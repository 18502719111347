


/* Base */
body {
  line-height: 1.7;
  color: #4d4d4d;
  font-weight: 300;
  font-size: 1.1rem;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

::-moz-selection {
  background: #000;
  color: #fff; }

::selection {
  background: #000;
  color: #fff; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; 
  text-decoration: none;
}
  a:hover {
    text-decoration: none; }

.text-black {
  color: #000 !important; }

.bg-black {
  background: #000 !important; }

.site-wrap:before {
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden; }

.offcanvas-menu .site-wrap {
  // position: absolute;
  height: 100%;
  width: 100%;
  // z-index: 2;
  overflow: hidden; }
  .offcanvas-menu .site-wrap:before {
    opacity: 1;
    visibility: visible; }

.btn {
  text-transform: uppercase;
  position: relative;
  -webkit-transition: 0.2s all ease-in-out !important;
  -o-transition: 0.2s all ease-in-out !important;
  transition: 0.2s all ease-in-out !important;
  top: 0;
  letter-spacing: .05em;
  padding: 10px 20px; 
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #000;
}
  .btn-outline-light {
    box-shadow: 0 2px 6px -5px rgba(0, 0, 0, 0.9) !important; 
  }
  .btn:hover, .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .btn.btn-secondary {
    background-color: #e6e7e9;
    border-color: #e6e7e9;
    color: #000; }
  .btn.btn-sm {
    font-size: 0.9rem; }
  .btn.btn-primary {
    font-weight: 300;
    letter-spacing: .2em; }
  .btn:hover {
    -webkit-box-shadow: 0 5px 20px -7px rgba(0, 0, 0, 0.9) !important;
    box-shadow: 0 5px 20px -7px rgba(0, 0, 0, 0.9) !important;
    top: -2px; }

.bg-black {
  background: #000; }

.form-control {
  height: 47px; }
  .form-control:active, .form-control:focus {
    border-color: var(--secondary); }
  .form-control:hover, .form-control:active, .form-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

.site-section {
  padding: 4.5em 0; }
  @media (min-width: 768px) {
    .site-section {
      padding: 5em 0; } }
  .site-section.site-section-sm {
    padding: 4em 0; }

.site-section-heading {
  font-size: 30px;
  color: #25262a;
  position: relative; }
  .site-section-heading:before {
    content: "";
    left: 0%;
    top: 0;
    position: absolute;
    width: 40px;
    height: 2px;
    background: var(--secondary); }
  .site-section-heading.text-center:before {
    content: "";
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    width: 40px;
    height: 2px;
    background: var(--secondary); }

.border-top {
  border-top: 1px solid #edf0f5 !important; }

.site-footer {
  padding: 4em 0;
  background: #333333; }
  .site-footer p {
    color: #737373; }
  .site-footer h2, .site-footer h3, .site-footer h4, .site-footer h5 {
    color: #fff; }
  .site-footer a {
    color: #999999; }
    .site-footer a:hover {
      color: white; }
  .site-footer ul li {
    margin-bottom: 10px; }
  .site-footer .footer-heading {
    font-size: 20px;
    color: #fff; }

.bg-text-line {
  display: inline;
  background: #000;
  -webkit-box-shadow: 20px 0 0 #000, -20px 0 0 #000;
  box-shadow: 20px 0 0 #000, -20px 0 0 #000; }

.bg-image {
  background-size: cover;
  background-repeat: no-rpeeat;
  overflow: hidden; }
  .bg-image.center {
    background-position: top center; }
  .bg-image.fixed {
    background-position: fixed !important; }
  .bg-image.overlay, .bg-image.overlay-primary, .bg-image.overlay-info, .bg-image.overlay-success, .bg-image.overlay-warning {
    position: relative; }
    .bg-image.overlay:before, .bg-image.overlay-primary:before, .bg-image.overlay-info:before, .bg-image.overlay-success:before, .bg-image.overlay-warning:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }
  .bg-image.overlay:before {
    background: rgba(0, 0, 0, 0.4); }
  .bg-image.overlay-primary:before {
    background: rgba(242, 58, 46, 0.9); }
  .bg-image.overlay-info:before {
    background: rgba(23, 162, 184, 0.9); }
  .bg-image.overlay-success:before {
    background: rgba(40, 167, 69, 0.9); }
  .bg-image.overlay-success:before {
    background: rgba(238, 198, 10, 0.9); }


/* Blocks */
.site-blocks-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top; }
  .site-blocks-cover.overlay {
    position: relative; }
    .site-blocks-cover.overlay:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.4); }
  .site-blocks-cover, .site-blocks-cover .row {
    min-height: 600px;
    height: calc(100vh); }
  .site-blocks-cover.inner-page, .site-blocks-cover.inner-page .row {
    min-height: 600px;
    height: calc(80vh); }
  .site-blocks-cover .sub-text {
    font-size: 1.1rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: .2em; }
  .site-blocks-cover h1 {
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    font-size: 3rem; }
    .site-blocks-cover h1 strong {
      font-weight: 900; }
    @media (min-width: 768px) {
      .site-blocks-cover h1 {
        font-size: 5rem;
        font-weight: 300;
        line-height: 1.2; } }
  .site-blocks-cover p {
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.5; }
  .site-blocks-cover .intro-text {
    font-size: 16px;
    line-height: 1.5; }

.site-heading {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 2rem; }
  @media (min-width: 768px) {
    .site-heading {
      font-size: 3rem; } }
  .site-heading strong {
    font-weight: 900; }

.site-block-1 {
  padding-top: 50px; }
  @media (min-width: 992px) {
    .site-block-1 {
      padding-top: 0;
      position: absolute;
      width: 100%;
      -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      transform: translateY(-100%); } }

.site-block-appointment, .bg-light {
  background: #ece8d9 !important; }
  .site-block-appointment form, .bg-light form {
    -webkit-box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1); }

.text-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5); }

.text-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5); }

.site-block-feature {
  background: rgba(0, 0, 0, 0.5);
  position: relative; }
  .site-block-feature .icon {
    font-size: 50px; }
  .site-block-feature h3 {
    font-size: 15px;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #fff; }
  .site-block-feature p {
    color: rgba(255, 255, 255, 0.4);
    font-size: 1rem; }
    .site-block-feature p:last-child {
      margin-bottom: 0; }

.site-block-feature-2 .icon {
  font-size: 50px;
  position: relative;
  line-height: 1; }

.site-block-feature-2 h3 {
  font-size: 1.5rem; }

.site-block-3 .row-items {
  height: calc(50% - 15px);
  margin-bottom: 30px; }
  .site-block-3 .row-items.last {
    height: calc(50% - 15px);
    margin-bottom: 0; }

.site-block-3 .feature {
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  height: 100%; }
  .site-block-3 .feature h3 {
    color: #000;
    font-size: 1.4rem; }
  .site-block-3 .feature:hover, .site-block-3 .feature:active, .site-block-3 .feature:focus, .site-block-3 .feature.active {
    -webkit-box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1); }

.site-block-half .image, .site-block-half .text {
  width: 100%; }
  @media (min-width: 992px) {
    .site-block-half .image, .site-block-half .text {
      width: 50%; } }

@media (max-width: 991.98px) {
  .site-block-half .image {
    margin-bottom: 50px; } }

.site-block-half .text {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 992px) {
    .site-block-half .text {
      padding: 4rem; } }
  @media (min-width: 1200px) {
    .site-block-half .text {
      padding: 7rem; } }

.site-block-half .bg-image {
  background-position: center center; }
  @media (max-width: 991.98px) {
    .site-block-half .bg-image {
      height: 400px; } }

.site-block-half.site-block-video .image {
  position: relative; }
  .site-block-half.site-block-video .image .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 20px;
    width: 70px;
    height: 70px;
    background: #fff;
    display: block;
    border-radius: 50%;
    opacity: 1;
    color: var(--secondary) !important; }
    .site-block-half.site-block-video .image .play-button:hover {
      opacity: 1; }
    .site-block-half.site-block-video .image .play-button > span {
      position: absolute;
      left: 55%;
      top: 50%;
      -webkit-transform: translate(-60%, -50%);
      -ms-transform: translate(-60%, -50%);
      transform: translate(-60%, -50%); }

.site-block-testimony img {
  max-width: 140px;
  border-radius: 50%; }

.site-block-testimony p {
  font-size: 1.2rem; }

.site-block-testimony.active {
  background: #fff;
  -webkit-box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1); }

.post-entry {
  position: relative;
  overflow: hidden;
  border: 1px solid #e6e6e6; }
  .post-entry, .post-entry .text {
    border-radius: 4px;
    border-top: none !important; }
  .post-entry .date {
    color: #ccc;
    font-weight: normal;
    letter-spacing: .1em; }
  .post-entry .image {
    overflow: hidden;
    position: relative; }
    .post-entry .image img {
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
  .post-entry:hover .image img, .post-entry:active .image img, .post-entry:focus .image img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }

.block-13, .slide-one-item {
  position: relative;
  z-index: 1; }
  .block-13 .owl-nav, .slide-one-item .owl-nav {
    position: relative;
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
    .block-13 .owl-nav .owl-prev, .block-13 .owl-nav .owl-next, .slide-one-item .owl-nav .owl-prev, .slide-one-item .owl-nav .owl-next {
      position: relative;
      display: inline-block;
      padding: 20px;
      font-size: 30px;
      color: #fff; }
      .block-13 .owl-nav .owl-prev.disabled, .block-13 .owl-nav .owl-next.disabled, .slide-one-item .owl-nav .owl-prev.disabled, .slide-one-item .owl-nav .owl-next.disabled {
        opacity: .2; }

.slide-one-item.home-slider .owl-nav {
  bottom: 40px; }
  @media (min-width: 768px) {
    .slide-one-item.home-slider .owl-nav {
      bottom: 80px; } }

.slide-one-item .owl-item > div {
  height: 300px; }
  @media (min-width: 768px) {
    .slide-one-item .owl-item > div {
      height: 800px; } }

.block-12 {
  position: relative; }
  .block-12 figure {
    position: relative; }
    .block-12 figure:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
      background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); }
  .block-12 .text-overlay {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    z-index: 10; }
    .block-12 .text-overlay h2 {
      color: #fff; }
  .block-12 .text {
    position: relative;
    top: -100px; }
    .block-12 .text .meta {
      text-transform: uppercase;
      padding-left: 40px;
      color: #fff;
      display: block;
      margin-bottom: 20px; }
    .block-12 .text .text-inner {
      -webkit-box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
      background: #fff;
      padding: 10px;
      margin-right: 30px;
      position: relative; }
      @media (min-width: 576px) {
        .block-12 .text .text-inner {
          padding: 20px; } }
      @media (min-width: 768px) {
        .block-12 .text .text-inner {
          padding: 30px 40px; } }
      .block-12 .text .text-inner:before {
        position: absolute;
        content: "";
        width: 80px;
        height: 4px;
        margin-top: -4px;
        background: var(--secondary);
        top: 0; }
      .block-12 .text .text-inner .heading {
        font-size: 20px;
        margin: 0;
        padding: 0; }

.block-16 figure {
  position: relative; }
  .block-16 figure .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 20px;
    width: 70px;
    height: 70px;
    background: #fff;
    display: block;
    border-radius: 50%;
    opacity: 1;
    color: var(--secondary) !important; }
    .block-16 figure .play-button:hover {
      opacity: 1; }
    .block-16 figure .play-button > span {
      position: absolute;
      left: 55%;
      top: 50%;
      -webkit-transform: translate(-60%, -50%);
      -ms-transform: translate(-60%, -50%);
      transform: translate(-60%, -50%); }

.block-25 ul, .block-25 ul li {
  padding: 0;
  margin: 0; }

.block-25 ul li .date {
  color: rgba(255, 255, 255, 0.25);
  font-size: 0.8rem;
  display: block; }

.block-25 ul li a .image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px; }
  .block-25 ul li a .image img {
    border-radius: 4px;
    max-width: 100%;
    opacity: 1;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }

.block-25 ul li a .text .heading {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  color: #999999; }

.block-25 ul li a .meta {
  color: var(--secondary); }

.block-25 ul li a:hover img {
  opacity: .5; }

.block-25 ul li a:hover .text .heading {
  color: #fff; }

#date-countdown .countdown-block {
  color: rgba(255, 255, 255, 0.4); }

#date-countdown .label {
  font-size: 40px;
  color: #fff; }

.next-match .image {
  width: 50px;
  border-radius: 50%; }

.player {
  position: relative; }
  .player img {
    max-width: 100px;
    border-radius: 50%;
    margin-bottom: 20px; }
  .player .team-number {
    position: absolute;
    width: 30px;
    height: 30px;
    background: var(--secondary);
    border-radius: 50%;
    color: #fff; }
    .player .team-number > span {
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%; }
  .player h2 {
    font-size: 20px;
    letter-spacing: .2em;
    text-transform: uppercase; }
  .player .position {
    font-size: 14px;
    color: #b3b3b3;
    text-transform: uppercase; }

.site-block-27 ul, .site-block-27 ul li {
  padding: 0;
  margin: 0; }

.site-block-27 ul li {
  display: inline-block;
  margin-bottom: 4px; }
  .site-block-27 ul li a, .site-block-27 ul li span {
    text-align: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc; }
  .site-block-27 ul li.active a, .site-block-27 ul li.active span {
    background: var(--secondary);
    color: #fff;
    border: 1px solid transparent; }


    // scroll bar
    ::-webkit-scrollbar {
      width: 10px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
      background: #f1f1f1; 
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
      background: #888; 
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
      background: #555; 
      }



      // TOASTIFY
      .Toastify__progress-bar--default {
        background: #83c7d399;  
      }
      
      .Toastify__toast--default {
        color: #4d4d4d;
        font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      }