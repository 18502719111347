.site-footer{
	background-color: #292c2f;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: normal 16px sans-serif;

	padding: 45px 50px;
	margin-top: 80px;
}

.site-footer .footer-left p{
	color:  #8f9296;
	font-size: 14px;
	margin: 0;
}

/* Footer links */

.site-footer p.footer-links{
	font-size:px;
	font-weight: bold;
	color:  #ffffff;
	margin: 0 0 10px;
	padding: 0;
}

.site-footer p.footer-links a{
	display:inline-block;
	line-height: 1.8;
	text-decoration: none;
	color:  inherit;
}

.site-footer .footer-right{
	float: right;
	margin-top: 6px;
	max-width: 180px;
}

.site-footer .footer-right a{
	display: inline-block;
	width: 35px;
	// height: 35px;
	// background-color:  #33383b;
	border-radius: 2px;

	font-size: 2.25rem;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-left: 3px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 600px) {

	.site-footer .footer-left,
	.site-footer .footer-right{
		text-align: center;
	}

	.site-footer .footer-right{
		float: none;
		margin: 0 auto 20px;
	}

	.site-footer .footer-left p.footer-links{
		line-height: 1.8;
	}
}
