#parallax-b {

    h1 {    
        position: absolute;
        z-index: 11;
        color: white;
        text-align: center;
        position: absolute;
        left: 30px;
        margin-left:auto;
        margin-right:auto;
        top: 40%;
        width: 0%;
    }
}