.site-block-braces {
    // background-color: #f5f5f5;

    .jarallax-braces {
        >div {
            border-radius: 0.25rem;
        }
    }
}

.process,
.service {
    position: relative;
}

.process .number,
.service .number {
    position: absolute;
    top: 50px;
    left: -30px;
    line-height: 0;
    z-index: -1;
    font-size: 10rem;
    color: whitesmoke;
    font-weight: 900;
}

.process h3,
.service h3 {
    font-size: 1.5rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.service {
    padding: 4rem;
    background: #fff;

}
.flaticon{
    color: var(--primary)
}

@media (max-width: 991.98px) {
    .service {
        padding: 2rem;
    }
}